import React, { useEffect } from 'react';
import {
  faCheck,
  faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons';

import Section from '@heureca/shared/components/Section';
import Illustration from '@assets/signUp/index';
import useSignUp from '@heureca/signUp/hooks/useSignUp.hook';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import Layout from '@heureca/shared/components/Layout';

import EndCardContainer from '../EndCardContainer';

import * as styles from './success.module.scss';

const successCards = [
  {
    type: 'success',
    image: Illustration.SignUp,
    title: 'Inscription',
    description: 'Vous créez votre compte Heureca',
    icon: faCheck,
  },
  {
    type: 'success',
    image: Illustration.Check,
    title: 'Vérification',
    description: 'Nous vérifions vos informations',
    icon: faHourglassHalf,
  },
  {
    type: 'waiting',
    image: Illustration.Password,
    title: 'Confirmation',
    description: 'Vous recevez un mail pour créer votre mot de passe',
  },
  {
    type: 'waiting',
    image: Illustration.Success,
    title: 'Succès',
    description: 'Configurez votre site et partagez le avec vos clients',
  },
];

const Success = () => {
  const { reset } = useSignUp();
  const { translate } = useTranslations();

  useEffect(() => {
    reset();
  }, []);

  return (
    <Layout pageTitle={translate('signUp.title')} isSignUp>
      <Section name="Succès" title={translate('steps.success.title')} containerClassNames={styles.content}>
        <EndCardContainer
          title="Y'a plus qu'a !"
          cards={successCards}
        />
      </Section>
    </Layout>
  );
};

export default Success;
